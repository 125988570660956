.list{
    width: 100%;
    height: 5rem;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 0 auto;
    padding: 0 2rem;
}

.logo-nav{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.logo{
    height: 4rem;
}

.logo-title{
    padding-bottom: 0.5rem;
}

.logo-title a{
    text-decoration: none;
    color: white;
    font-size: 2rem;
    padding-left: 0.3rem;
    font-weight: 600;
}

.dropdown-menu a{
    text-decoration: none;
    color: white;
}

.items-nav{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.items-nav a{
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    opacity: 0.75;
}

.items-nav a:hover{
    opacity: 1;
}

.toggle-btn{
    font-size: 2rem;
    cursor: pointer;
    display: none;
    border: none;
    background: none;
}

/* DROPDOWN MENU */
.dropdown-menu{
    display: none;
    position: absolute;
    right: 2rem;
    top: 5rem;
    height: 0;
    width: 300px;
    background-color: rgba(255,255,255,.1);
    box-shadow: 0 0 10px rgba(0,0,0, 0.25);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    overflow: hidden;
    transition: height 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown-menu.open{
    height: 225px;
}

.dropdown-menu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-menu a{
    opacity: 0.8;
}

.dropdown-menu a:hover{
    opacity: 1;
}

.contact-us{
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.5s;
}

.contact-us:hover{
    opacity: 1;
}

.contact-us a{
    text-decoration: none;
    color: #fff;
    text-shadow: 0 0 5px #fff,
    0 0 25px #fff,
    0 0 50px #fff,
    0 0 100px #fff;
}

.language{
    display: flex;
    align-items: center;
}

.language img{
    cursor: pointer;
}

.arg{
    margin-right: 1rem;
}

.flag-dropdownBtn{
    display: flex;
    align-items: center;
}

.toggle-btn{
    margin-left: 1rem;
}

/* RESPONSIVE */

@media(max-width: 1100px){
    .items-nav,
    .contact-us{
        display: none;
    }

    .toggle-btn{
        display: block;
    }

    .dropdown-menu{
        display: block;
    }

    .dropdown-menu a{
        opacity: 1;
    }

    .contact-us{
        font-size: 1rem;
        opacity: 1;
    }
}

@media(max-width: 576px){
    .dropdown-menu{
        left: 2rem;
        width: unset;
    }

    .arg{
        margin-right: 0;
    }

    .list{
        justify-content: space-around;
        padding: 0 0.1rem;
    }
}

@media(max-width: 420px){
    .toggle-btn{
        margin-left: 0.2rem;
    }
}