footer{
    position: relative;
    width: 100%;
    background: #3586ff;
    min-height: 100px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.icons,
.menu{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}

.icons li,
.menu li{
    list-style: none;
}

.icons a{
    font-size: 2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

.icons a:hover{
    transform: translateY(-10px);
}

.menu a{
    font-size: 1.2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    text-decoration: none;
    opacity: 0.75;
}

.menu a:hover{
    opacity: 1;
}

.contactUs{
    font-weight: bold;
    cursor: pointer;
}

.contactUs a{
    text-decoration: none;
    color: #fff;
    text-shadow: 0 0 5px #fff,
    0 0 25px #fff,
    0 0 50px #fff,
    0 0 100px #fff;
}

.rights{
    color: #fff;
    text-align: center;
    margin: 15px 0 10px 0;
    font-size: 1.1em;
}

.wave{
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url('../../img/wave.png');
    background-size: 1000px 100px;
    margin-top: 1.5em;
}

.wave#wave1{
    z-index: 2;
    opacity: 1;
    bottom: 0;
    animation: animateWave 4s linear infinite;
}

.wave#wave2{
    z-index: 1;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave_02 4s linear infinite;
}

.wave#wave3{
    z-index: 2;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWave 3s linear infinite;
}

.wave#wave4{
    z-index: 1;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave_02 3s linear infinite;
}

#btn-call-footer{
    margin-bottom: 2rem;
}

@keyframes animateWave{
    0%{
        background-position-x: 1000px;
    }

    100%{
        background-position-x: 0px;
    }
}

@keyframes animateWave_02{
    0%{
        background-position-x: 0px;
    }

    100%{
        background-position-x: 1000px;
    }
}

@media (max-width: 992px){
    .menu a{
        opacity: 1;
    }
}