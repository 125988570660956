@font-face {
    font-family: OpenSauce;
    src: url('../../fonts/OpenSauceOne-Regular.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Tomorrow&display=swap');

/* MOBILE / TABLET */

.title2{
    font-family: OpenSauce;
    font-size: 2rem;
    font-weight: 600;
    padding-top: 1.5rem;
    padding-left: 1.5rem;   
}

.take-yourE{
    color: #1867BE;
}

.info2nd{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paid-ads,
.influencers{
    background: rgb(52, 147, 255);
    background: linear-gradient(180deg, rgba(68,153,251,1) 41%, rgba(22,63,241,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 3rem;
    width: 15.25rem;
    padding: 2rem 3rem 2rem 3rem;
    gap: 2rem;
}

.paid-ads{
    justify-content: space-around;
    height: 22rem;
    margin-top: 1.5rem;
}

.influencers{
    height: 22rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.paid-ads p:first-child,
.inf-title{
    font-family: OpenSauce;
    color: white;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}

.flechas{
    height: 5rem;
}

.detail2nd{
    text-align: center;
    color: white;
    font-family: 'Tomorrow', sans-serif;
}

.btn,
.btn2{
    text-align: center;
}

.btn{
    display: none;
}

#btn1-call-2nd{
    margin-top: 2rem;
}

#btn2-call-2nd{
    margin-bottom: 2rem;
}

.title-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* TABLET */

@media (min-width: 768px){
    .info2nd{
        flex-direction: row;
        justify-content: space-around;
    }

    .paid-ads{
        justify-content: space-between;
        margin: 0;
    }

    .influencers{
        margin: 3rem 0 3rem 0;
    }

    .paid-ads p:first-child{
        padding-top: 1rem;
    }

    #flechas1{
        margin-top: 1.5rem;
    }
}

/* LAPTOP */

@media (min-width: 992px){
    .secondpage{
        display: flex;
        justify-content: space-around;
    }

    .info2nd{
        flex-direction: row;
    }

    .title2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        width: 20rem;
    }

    .paid-ads{
        margin: 0 4rem 0 0;
    }

    .influencers{
        margin: 3rem 0 3rem 0;
    }

    .btn2{
        display: none;
    }

    .btn{
        display: block;
    }
}

/* DESKTOP */

@media (min-width: 1200px){
    .secondpage{
        width: 1200px;
        margin: 0 auto;
    }

    .paid-ads,
    .influencers{
        height: 27rem;
        width: 18rem;
    }

    .flechas{
        height: 7rem;
    }

    .paid-ads p:first-child,
    .inf-title{
        font-size: 2.5rem;
    }

    .detail2nd{
        font-size: 1.2rem;
    }

    .title2{
        font-size: 2.3rem;
    }

    #btn1-call-2nd{
        margin-right: 2rem;
    }

    #flechas1{
        margin-top: 2rem;
    }
}