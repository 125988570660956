@font-face {
    font-family: Agrandir;
    src: url('../../fonts/Agrandir-WideLight.otf');
}

@font-face {
    font-family: OpenSauce;
    src: url('../../fonts/OpenSauceOne-Regular.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Tomorrow&display=swap');

/* MOBILE / TABLET */

.olas{
    height: 20rem;
}

.homepage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info1st p{
    text-align: center;
}

.blow-up{
    font-family: Agrandir;
    font-size: 3rem;
    color: white;
    height: 4rem;
}

.blow-up:first-child{
    padding-top: 1rem;
}

.maximize,
.maximize1{
    font-family: 'Tomorrow', sans-serif;
    color: white;
}

.maximize1{
    padding-top: 3rem;
}

.maximize{
    padding-bottom: 1.5rem;
}

.info1st{
    text-align: center;
}

.btn-call{
    font-family: 'OpenSauce';
    font-size: 1.05rem;
    font-weight: 600;
    padding: 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    background-color: #0C98FC;
    margin: 1rem 0;
    color: white;
}

#btn-call-homepage{
    margin-bottom: 2rem;
}

/* LAPTOP */

@media (min-width: 992px){
    .homepage{
        flex-direction: row;
        justify-content: space-around;
        width: 992px;
        margin: 0 auto;
    }

    .olas{
        height: 25rem;
        margin-bottom: 2rem;
    }

    .blow-up{
        font-size: 4rem;
        height: 5rem;
    }

    .maximize,
    .maximize1{
        font-size: 1.1rem;
        
    }
}

/* DESKTOP */

@media (min-width: 1200px){
    .homepage{
        justify-content: space-around;
        width: 1200px;
    }

    .olas{
        height: 30rem;
        margin-bottom: 2rem;
    }

    .blow-up{
        font-size: 5rem;
        height: 5rem;
    }

    .blow-up:first-child{
        padding-top: 0;
    }

    .maximize1{
        padding-top: 6rem;
    }

    .maximize,
    .maximize1{
        font-size: 1.3rem;
    }
}