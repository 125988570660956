@font-face {
    font-family: OpenSauce;
    src: url('../../fonts/OpenSauceOne-Regular.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Tomorrow&display=swap');

/* MOBILE */

.fourthpage{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-bottom: 5rem;
}

.info4th{
    display: flex;
    flex-direction: column;
}

.fourth-title{
    font-family: OpenSauce;
    font-size: 2.5rem;
    background: rgb(52, 147, 255);
    background: linear-gradient(180deg, rgba(68,153,251,1) 41%, rgba(22,63,241,1) 100%);
    color: white;
    margin: 1.5rem 1rem 0 1rem;
    padding: 1rem 2rem;
    font-weight: 600;
    border-radius: 20px;
}

.cuadro1{
    height: 4rem;
    margin-top: 1rem;
}

.campaign{
    background: rgb(52, 147, 255);
    background: linear-gradient(180deg, rgba(68,153,251,1) 41%, rgba(22,63,241,1) 100%);
    margin-top: 1.5rem;
    border-radius: 0 0 6.5rem 6.5rem;
    width: 16rem;
}

.campaign:last-child{
    margin-bottom: 1.5rem;
}

.content4th{
    padding: 0 2.5rem 2rem 2.5rem;
}

.content4th p:first-child{
    font-family: OpenSauce;
    padding: 0.2rem 0 1rem 0;
    font-weight: 600;
    color: white;
}

.detail4th{
    font-family: 'Tomorrow', sans-serif;
    font-size: 0.9rem;
    color: white;
}

.detail4th-last{
    font-family: 'Tomorrow', sans-serif;
    font-size: 0.9rem;
    color: white;
    padding-top: 0.5rem;
}

/* TABLET */

@media (min-width: 768px){
    .campaign p:first-child{
        font-size: 1.2rem;
    }

    .detail4th{
        font-size: 1.05rem;
    }

    .detail4th-last{
        font-size: 1.05rem;
    }

    .fourth-title{
        font-size: 3rem;
    }
}

/* LAPTOP */

@media (min-width: 992px){
    .info4th{
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        padding: 1.5rem 0;
    }

    .campaign{
        margin: 0;
    }

    .campaign:last-child{
        margin: 0;
    }

    #detail4th-p{
        padding-top: 1.5rem;
    }
}

/* DESKTOP */

@media (min-width: 1200px){
    .info4th{
        width: 1200px;
    }

    .campaign p:first-child{
        font-size: 1.5rem;
    }

    .detail4th{
        font-size: 1.1rem;
    }

    #detail4th-p{
        padding-top: 1.8rem;
    }

    .campaign{
        width: 17rem;
    }
}