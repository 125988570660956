@font-face {
    font-family: OpenSauce;
    src: url('../../fonts/OpenSauceOne-Regular.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Tomorrow&display=swap');

/* MOBILE */

.thirdpage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info3rd{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.third-title p{
    font-family: OpenSauce;
    font-size: 2.5rem;
    color: white;
    font-weight: 600;
    padding-top: 1.5rem;
}

.transparency p:first-child,
.results p:first-child,
.innovation p:first-child{
    font-family: OpenSauce;
    font-size: 1.5rem;
    color: white;
}

.transparency p:first-child{
    padding-top: 1.5rem;
}

.detail3rd{
    font-family: 'Tomorrow', sans-serif;
    color: white;
    padding: 0.8rem 1rem 0 0.8rem;
}

hr{
    border: solid lightgray 1px;
    margin: 1.5rem 1rem 1.5rem 1rem;
}

.info3rd:last-child{
    padding-bottom: 1.5rem;
}

/* TABLET */

@media(min-width: 660px){
    .detail3rd{
        width: 650px;
    }
}

@media (min-width: 768px){
    .third-title p{
        font-size: 3rem;
    }

    .transparency p:first-child,
    .results p:first-child,
    .innovation p:first-child{
        font-size: 1.7rem;
    }
    
    .detail3rd{
        width: 650px;
    }
}

/* LAPTOP */

@media (min-width: 992px){
    hr{
        display: none;
    }

    .info3rd{
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 3rem;
    }

    .detail3rd{
        margin: 0 auto;
        padding: 1rem 0 0 0;
    }

    #detail3rd-p{
        width: 13rem;
    }

    .detail3rd{
        width: 12rem;
        font-size: 1.1rem;
    }

    .third-title p{
        font-size: 3.8rem;
    }

    .transparency p:first-child,
    .results p:first-child,
    .innovation p:first-child{
        font-size: 2rem;
        padding: 0;
    }
    
    .info3rd{
        padding-top: 3rem;
    }
}

/* DESKTOP */

@media (min-width: 1200px){
    .info3rd{
        width: 1200px;
        margin: 0 auto;
    }
}