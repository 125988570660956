*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.first{
    background: rgb(52, 147, 255);
    background: linear-gradient(180deg, rgba(68,153,251,1) 41%, rgba(22,63,241,1) 100%);
}

.third{
    background: rgb(52, 147, 255);
    background: linear-gradient(180deg, rgba(68,153,251,1) 41%, rgba(22,63,241,1) 100%);
}
