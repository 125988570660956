@font-face {
    font-family: OpenSauce;
    src: url('../../fonts/OpenSauceOne-Regular.ttf');
}
.partners{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title-partners{
    font-family: OpenSauce;
    font-size: 2rem;
    padding: 2rem 0;
    text-align: center;
    font-weight: 600;
}
.container-partners{
    background-color: #f5f7fa;
    border-bottom: 1px solid #e4ebf3;
    border-top: 1px solid #e4ebf3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 60px 30px;
    gap: 3rem;
}
.logo-purplemango{
    height: 70px;
}
.logo-olimpsay{
    height: 40px;
}
.logo-N{
    height: 60px;
}
.logo-helpem{
    height: 70px;
}
.logo-goldenbird{
    height: 90px;
}
.logo-blackfyre{
    height: 70px;
}