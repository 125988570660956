@font-face {
    font-family: OpenSauce;
    src: url('../../fonts/OpenSauceOne-Regular.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Tomorrow&display=swap');

.calendly{
    background: rgb(52, 147, 255);
    background: linear-gradient(180deg, rgba(68,153,251,1) 41%, rgba(22,63,241,1) 100%);
    height: 100%;
}

.calendly-title{
    font-family: 'OpenSauce';
    font-size: 2.3rem;
    text-align: center;
    margin: 0 5rem;
    background-color: #2d77e6;
    color: white;
    padding: 0.5rem;
    font-weight: 600;
    border-radius: 20px;
}

.calendly-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.title-calendly{
    font-family: 'OpenSauce';
    font-size: 1.5rem;
    font-weight: 600;
    padding: 2rem 0 1rem 0;
    text-align: center;
}

.list-calendly,
.info-calendly{
    padding: 0 3.5rem;
    font-family: 'Tomorrow', sans-serif;
    font-size: 1.1rem;
}

.info-calendly{
    padding-bottom: 2rem;
}

/* TABLET */

@media (min-width: 768px){
    .calendly-items{
        max-width: 800px;
        padding: 0;
        margin: 0 auto;
        justify-content: center;
        font-size: 2.5rem;
        
    }

    .calendly-items{
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
    }

    .infoCalendly{
        width: 30rem;
    }

    .title-calendly{
        font-size: 1.6rem;
    }
    
    .list-calendly,
    .info-calendly{
        font-size: 1.2rem;
    }
}

/* LAPTOP */

@media (min-width: 992px){
    .calendly-title{
        font-size: 3rem;
        max-width: 800px;
        margin: 0 auto;
    }

    .title-calendly{
        font-size: 1.8rem;
    }
    
    .list-calendly,
    .info-calendly{
        font-size: 1.25rem;
    }
}